// @file Handles Canvas format resizing
import { $ } from '@@/bits/jquery_ui_resizable'
import { getJsPlumbInstance } from '@@/bits/jsplumb'
import { useSurfaceStore } from '@@/pinia/surface'
import '@@/vendor/toe.min'
import { mapState as piniaMapState } from 'pinia'

export default {
  data() {
    return {
      $_resizableMixin_savePostSizeHandler: null,
      $_resizableMixin_previewAspectRatio: null,
    }
  },
  computed: {
    ...piniaMapState(useSurfaceStore, ['wallCreatedAt']),
  },
  beforeDestroy() {
    this.destroyResizable()
  },
  methods: {
    initializeResizable(mousable, savePostSizeHandler) {
      this.$_resizableMixin_savePostSizeHandler = savePostSizeHandler
      this.$nextTick(() => {
        mousable ? this.$_resizableMixin_makeResizable() : this.$_resizableMixin_makeResizableTouch()
      })
    },
    destroyResizable() {
      this.$_resizableMixin_destroyResizable()
    },
    $_resizableMixin_resizeAttachment(width) {
      const previewAspectRatio = this.$_resizableMixin_getAttachmentAspectRatio()
      const imageGridAspectRatio = this.$_resizableMixin_getImageGridAspectRatio()
      const imageGridNumOfImages = this.$_resizableMixin_getImageGridNumOfImages()
      const usingImageGrid = imageGridNumOfImages > 0

      // Used only when ?old=1 on surface
      const dimensions = {
        width,
        height: Math.floor(width / previewAspectRatio),
      }
      $('.beethoven-preview-image', this.$el).attr(dimensions).css(dimensions)
      $('.beethoven-inline-embed iframe', this.$el).attr(dimensions).css(dimensions)

      // Post View v2 with padding on sizes of attachment and new components
      const postV2Dimensions = {
        width: width - 16,
        height: Math.floor((width - 16) / previewAspectRatio),
      }
      $('.surface-post-attachment-inline-embed iframe', this.$el).attr(postV2Dimensions).css(postV2Dimensions)

      if (usingImageGrid) {
        // Post View v2 with image grid: we only resize the grid container as the images in the grid always
        // expand to fill the container
        // When the grid has one image, we use that image's aspect ratio
        const ar = imageGridNumOfImages === 1 ? previewAspectRatio : imageGridAspectRatio
        const imageGridDimensions = {
          width: width - 16,
          height: Math.floor((width - 16) / ar),
        }
        $('.image-grid-container', this.$el).attr(imageGridDimensions).css(imageGridDimensions)
      } else {
        // If not using image grid, we just resize the thumbnail image
        $('.image-thumbnail-crop-frame,.image-thumbnail-image', this.$el).attr(postV2Dimensions).css(postV2Dimensions)
      }
    },
    $_resizableMixin_saveSize(newSize) {
      this.$_resizableMixin_savePostSizeHandler({
        postCid: this.post.cid,
        size: newSize,
      })
    },
    $_resizableMixin_getAttachmentAspectRatio() {
      return $('[data-aspect-ratio]', this.$el).data('aspect-ratio')
    },
    $_resizableMixin_getImageGridAspectRatio() {
      return $('.image-grid-container', this.$el).data('image-grid-aspect-ratio')
    },
    $_resizableMixin_getImageGridNumOfImages() {
      return $('.image-grid-container', this.$el).data('image-grid-num-of-images') || 0
    },
    $_resizableMixin_makeResizable() {
      let $realWish = $(this.$el)
      let $el = $(this.$el)

      if ($realWish.hasClass('ui-resizable')) {
        $realWish.resizable(false)
      }

      // some users with existing canvas walls complained about new min width. so we only apply it to walls made after 2023-09-15
      // which is when we introduced the new min width
      const CANVAS_OLD_POSTS_MIN_WIDTH = 48
      const CANVAS_NEW_POSTS_MIN_WIDTH = 216
      const CANVAS_NEW_POSTS_MIN_WIDTH_TIMESTAMP = '2023-09-15'
      const isOldWall = Date.parse(this.wallCreatedAt) < Date.parse(CANVAS_NEW_POSTS_MIN_WIDTH_TIMESTAMP)
      const canvasPostMinWidth = isOldWall ? CANVAS_OLD_POSTS_MIN_WIDTH : CANVAS_NEW_POSTS_MIN_WIDTH
      $realWish.resizable({
        minWidth: canvasPostMinWidth,
        handles: 'e,se',
        resize: (event, ui) => {
          let elementWidth = $el.width()
          let uiWidth = ui.size.width
          if (elementWidth !== uiWidth) {
            $el.width(uiWidth)
          }
          this.$_resizableMixin_resizeAttachment(uiWidth)
          $realWish.removeClass('auto-width')
          $realWish.css('height', 'auto')
          if (ui.size.width < 100) {
            $realWish.addClass('wish-small')
          } else {
            $realWish.removeClass('wish-small')
          }
          getJsPlumbInstance()?.revalidate(this.$el)
        },
        start: (event, ui) => {
          $el.addClass('in-transit')
        },
        stop: (event, ui) => {
          var data
          $el.removeClass('in-transit')
          data = {}
          data.width = ui.size.width
          this.$_resizableMixin_saveSize(data)
        },
        zIndex: 1003,
      })
    },
    $_resizableMixin_makeResizableTouch() {
      let screenOrigin = {
        x: 0,
        y: 0,
      }

      let startWidth = 0
      let newWidth = 0

      let data = {}

      let $el = $(this.$el)

      $el.on('transformstart.resize', data, (e) => {
        this.$_resizableMixin_previewAspectRatio = $('[data-aspect-ratio]', $el).data('aspect-ratio')
        screenOrigin.x = (e.originalEvent.touches[0].clientX + e.originalEvent.touches[1].clientX) / 2
        screenOrigin.y = (e.originalEvent.touches[0].clientY + e.originalEvent.touches[1].clientY) / 2
        startWidth = $el.width()
        $el.addClass('in-transit')
      })

      $el.on('transform.resize', data, (e) => {
        newWidth = Math.max(startWidth * e.scale, 48)
        $el.css('width', newWidth)
        this.$_resizableMixin_resizeAttachment(newWidth)
      })

      $el.on('transformend.resize', data, (e) => {
        this.$_resizableMixin_saveSize({
          width: newWidth,
        })
        $el.removeClass('in-transit')
      })
    },
    $_resizableMixin_destroyResizable() {
      if (!this.$el) return
      const resizableInstance = $(this.$el).off('.resize').resizable('instance')
      if (resizableInstance) resizableInstance.destroy()
    },
  },
}
