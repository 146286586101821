/**
 * @file Provides jsPlumb to surface for draweing connection on canvas
 * Uses dynamic import to split into chunk in webpack to prevent jsplumb being loaded
 * on other formats
 */

import window from '@@/bits/global'
import type { BrowserJsPlumbDefaults, BrowserJsPlumbInstance, DragOptions } from '@jsplumb/browser-ui'

export async function initiateJsPlumb(
  options: BrowserJsPlumbDefaults & { dragOptions: DragOptions & { constrainFunction?: Function } },
): Promise<BrowserJsPlumbInstance | undefined> {
  window.jsPlumbInstance = (await getJsPlumb())?.newInstance(options)
  if (window.jsPlumbInstance == null) return undefined
  window.jsPlumbInitiated = true
  return window.jsPlumbInstance
}

export function getJsPlumbInstance(): BrowserJsPlumbInstance | undefined {
  return window.jsPlumbInstance
}

export async function getJsPlumb(): Promise<typeof import('@jsplumb/browser-ui') | undefined> {
  return await new Promise((resolve) => {
    import('@jsplumb/browser-ui')
      .then((jsPlumb) => {
        resolve(jsPlumb)
      })
      .catch((_error) => {
        resolve(undefined)
      })
  })
}

export async function getJsPlumbBezierConnector(): Promise<any> {
  return await new Promise((resolve) => {
    import('@jsplumb/connector-bezier')
      .then(({ BezierConnector }) => {
        resolve(BezierConnector)
      })
      .catch((_error) => {
        resolve(undefined)
      })
  })
}
