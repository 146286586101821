<script setup lang="ts">
import { makeSingleClickOnlyHandler } from '@@/bits/click'
import { CONFIRM_MODERATION_REJECT_POST } from '@@/bits/confirmation_dialog'
import { dir } from '@@/bits/current_dir'
import device from '@@/bits/device'
import { isAppUsing } from '@@/bits/flip'
import { tailwindSurfaceFontClass } from '@@/bits/font'
import { canObserveIntersection, observeElementIntersection } from '@@/bits/intersection'
import overflowBorderRadiusTransformFixStyle from '@@/bits/overflow_border_radius_transform_fix'
import { postBannerBackgroundColorTailwindClass, postColorTailwindClass } from '@@/bits/post_color'
import { NON_STREAM_STANDARD_MAX_POST_SIZE, NON_STREAM_WIDE_MAX_POST_SIZE } from '@@/bits/post_size'
import { isPostPinned, isPostScheduled } from '@@/bits/post_state'
import { observeSizeChange } from '@@/bits/resize_observer'
import { formatScheduledDateAndTime } from '@@/bits/scheduling'
import { getDateStringForDisplay } from '@@/bits/time'
import { isUrl } from '@@/bits/url'
import { defineAsyncComponent } from '@@/bits/vue'
import { useExpandedPostStore } from '@@/pinia/expanded_post'
import { useGlobalConfirmationDialogStore } from '@@/pinia/global_confirmation_dialog'
import { useSurfaceStore } from '@@/pinia/surface'
import { useCommentsStore } from '@@/pinia/surface_comments'
import { useSurfaceOnboardingDemoPadletPanelStore } from '@@/pinia/surface_onboarding_demo_padlet_panel_store'
import { useSurfacePermissionsStore } from '@@/pinia/surface_permissions'
import { useSurfacePostActionStore } from '@@/pinia/surface_post_action'
import { useSurfacePostConnectionStore } from '@@/pinia/surface_post_connection'
import { useSurfacePostPropertiesStore } from '@@/pinia/surface_post_properties'
import { useSurfacePostsStore } from '@@/pinia/surface_posts'
import { useReactionsStore } from '@@/pinia/surface_reactions'
import { useSurfaceSettingsStore } from '@@/pinia/surface_settings'
import { BreakPoints, useWindowSizeStore } from '@@/pinia/window_size'
import type { Comment, Id, Post, PostColor, WallReactionData, WallViz } from '@@/types'
import { debounce } from 'lodash-es'
// SurfacePostBody and SurfacePostAttachment should not be async since most post
// have either a body or an attachment
import type { AttachmentPostMessageEvent } from '@@/vuecomponents/SurfacePostAttachment.vue'
import SurfacePostAttachment from '@@/vuecomponents/SurfacePostAttachment.vue'
import SurfacePostBody from '@@/vuecomponents/SurfacePostBody.vue'
// SurfacePostMoreActions should not be async since we need to render this component most of the time
import SurfacePostMoreActions from '@@/vuecomponents/SurfacePostMoreActions.vue'
import { storeToRefs } from 'pinia'
import { computed, nextTick, onBeforeUnmount, onMounted, ref, watch } from 'vue'

const SurfacePostAuthor = defineAsyncComponent(() => import('@@/vuecomponents/SurfacePostAuthor.vue'))
const SurfacePostModeration = defineAsyncComponent(() => import('@@/vuecomponents/SurfacePostModeration.vue'))
const SurfacePostProperties = defineAsyncComponent(() => import('@@/vuecomponents/SurfacePostProperties.vue'))
const SurfacePostSocialContentTopBar = defineAsyncComponent(
  () => import('@@/vuecomponents/SurfacePostSocialContentTopBar.vue'),
)
const SurfacePostComments = defineAsyncComponent(() => import('@@/vuecomponents/SurfacePostComments.vue'))
const SurfacePostAttachmentEmbedControl = defineAsyncComponent(
  () => import('@@/vuecomponents/SurfacePostAttachmentEmbedControl.vue'),
)
const SurfacePostPoll = defineAsyncComponent(() => import('@@/vuecomponents/SurfacePostPoll.vue'))
const OzIcon = defineAsyncComponent(() => import('@@/library/v4/components/OzIcon.vue'))

const FIXED_POST_WIDTH: Partial<Record<WallViz, number>> = {
  timeline: NON_STREAM_STANDARD_MAX_POST_SIZE,
  table: 300,
}
const isSafari = device.safari
const postBannerRef = ref<HTMLDivElement>()
const beforeAttachmentRef = ref<HTMLDivElement>()
const userContentRef = ref<HTMLDivElement>()
const postContentRef = ref<HTMLDivElement>()
const attachmentRef = ref<InstanceType<typeof SurfacePostAttachment>>()
const surfacePostBeingShowedRef = ref<HTMLElement>()

const props = withDefaults(
  defineProps<{
    index: number
    post: Post
    fitHeightToContainer: boolean
    verticallyCenterContent: boolean
    postReactionIds?: Id[]
    postCommentIds?: Id[]
  }>(),
  {
    postReactionIds: () => [],
    postCommentIds: () => [],
  },
)

// **** GENERAL SHOW ATTRIBUTES ****
const {
  colorScheme,
  xAuthor,
  libraryId,
  xCommentsUnderPosts,
  isPostSizeWide,
  isPostSizeStandard,
  isFrozen,
  isUsedInEducationalContext,
  tenantId,
  isStream,
  isCanvas,
  isSectioned,
  isMap,
  isTimeline,
  fontId,
  xReactionsInScreenshotMode,
  xCommentsInScreenshotMode,
  isScreenshotMode,
  user,
  format,
} = storeToRefs(useSurfaceStore())
const { isSmallerThanDesktop, windowWidth } = storeToRefs(useWindowSizeStore())
const { isDemoPadletPanelDesktop } = storeToRefs(useSurfaceOnboardingDemoPadletPanelStore())
const surfacePostPropertiesStore = useSurfacePostPropertiesStore()
const { wallCustomPostProperties, isLocationEnabled } = storeToRefs(surfacePostPropertiesStore)
const { getDisplayedColorForPost, shouldShowPostProperties } = surfacePostPropertiesStore

const { postToConnectFromId, connections } = storeToRefs(useSurfacePostConnectionStore())

// Getters
const xRoleBadge = computed((): boolean => {
  // whitelist tenant_id 151209 (globalcampus.padlet.org) and library_id 4 (Stark Industries)
  if (tenantId.value === 151209 || libraryId.value === 4) return true
  return isAppUsing('surfaceRoleBadges') && isUsedInEducationalContext.value
})

const xPostProperties = computed((): boolean => {
  return shouldShowPostProperties(props.post)
})

const displayedPostColor = computed((): PostColor => {
  return getDisplayedColorForPost(props.post)
})

const isPadletColorSchemeDark = computed((): boolean => {
  return colorScheme.value === 'dark'
})

const postColorClass = computed((): string => {
  return postColorTailwindClass({
    postColor: displayedPostColor.value,
    isLightColorScheme: !isPadletColorSchemeDark.value,
  })
})

const getOverflowBorderRadiusTransformFixStyle = computed((): Record<string, string> => {
  return overflowBorderRadiusTransformFixStyle(isSafari)
})

const isAwaitingApproval = computed((): boolean => {
  return isPostNotApproved.value && !canIModerate.value
})

const isScheduled = computed((): boolean => {
  return isPostScheduled(props.post)
})

const xPin = computed((): boolean => {
  return isPostPinned(props.post)
})

const xPostBanner = computed((): boolean => {
  return isAwaitingApproval.value || isScheduled.value || xPin.value
})

const xPostSocialContentTopBar = computed((): boolean => {
  if (isScreenshotMode.value) {
    return (
      xReactions.value || (xCommentsInScreenshotMode.value && (props.postCommentIds.length > 0 || isCommentable.value))
    )
  }
  return xReactions.value || props.postCommentIds.length > 0 || !xCommentsUnderPosts.value
})

function areConnected(post1Id, post2Id): boolean {
  return (
    connections.value.filter((c) => {
      return (
        (c.from_wish_id === post1Id && c.to_wish_id === post2Id) ||
        (c.from_wish_id === post2Id && c.to_wish_id === post1Id)
      )
    }).length > 0
  )
}

const xConnect = computed((): boolean => {
  return (
    !!postToConnectFromId.value &&
    postToConnectFromId.value !== props.post.id &&
    !areConnected(props.post.id, postToConnectFromId.value)
  )
})

const scheduledAtDateString = computed((): string | undefined => {
  if (props.post.scheduled_at == null) return undefined
  return getDateStringForDisplay(props.post.scheduled_at)
})

const formattedScheduledDateAndTime = computed((): { date: string; time: string } => {
  return formatScheduledDateAndTime(props.post.scheduled_at!)
})

const subject = computed((): string | undefined => {
  return props.post.subject
})

const xSubject = computed((): boolean => {
  return !!subject.value
})

const body = computed((): string | undefined => {
  return props.post.body
})

const xBody = computed((): boolean => {
  return !!body.value
})

const tailwindFontClass = computed((): string => {
  return tailwindSurfaceFontClass(fontId.value)
})

/**
 * If the more button is on top of attachment, we make it overlay the attachment
 * Otherwise, we make the post text avoid the more button with a div with float: right
 */
const xInvisibleFloatingDiv = computed((): boolean => {
  if (!xAuthor.value && xSubject.value) return true
  if (!xAuthor.value && !xSubject.value && !xAttachment.value && !xPoll.value) return true
  return false
})

const xOverlayForMoreButtons = computed((): boolean => {
  if (xAuthor.value) return false
  return !xInvisibleFloatingDiv.value
})

/**
 * Only for timeline: the whole post can be scrolled.
 */
const isWishContentScrollable = computed((): boolean => {
  return isTimeline.value || isMap.value
})

/**
 * Only load attachment when there is one and when post is in viewport
 */
const xAttachment = computed((): boolean => {
  const attachment = props.post.attachment
  const hasAttachment = !!attachment && isUrl(attachment)
  return hasAttachment
})

const xPoll = computed((): boolean => {
  return props.post?.wish_content?.attachment_props?.type === 'poll'
})

const poll = computed(() => {
  return xPoll.value ? props.post?.wish_content?.attachment_props : null
})

// STALKER BAR
const xSocialContent = computed((): boolean => {
  return !isPostNotApproved.value && (xComments.value || xReactions.value)
})

// TODO: decouple these reactions and comments logic from SurfacePostBeingShowed
// REACTIONS
const { isReactable, isPreviewing } = storeToRefs(useSurfaceSettingsStore())
const { currentReactionData, originalReactionData, accumulatedReactionsByWishId } = storeToRefs(useReactionsStore())

const reactionData = computed((): WallReactionData => {
  return isPreviewing.value ? currentReactionData.value : originalReactionData.value
})

const xReactions = computed((): boolean => {
  if (isScreenshotMode.value && !xReactionsInScreenshotMode.value) return false
  // when a user is viewing a wall with reaction disabled and then reaction is enabled + someone react to something
  // the realtime message for new reaction may come before the reaction enable message
  // => wait for reactionData to be available before showing reactions
  const reactionCount =
    props.post.id != null ? accumulatedReactionsByWishId.value[props.post.id]?.totalReactionsCount : 0
  return (
    (isReactable.value || props.postReactionIds.length > 0 || (reactionCount !== undefined && reactionCount > 0)) &&
    !!reactionData.value
  )
})

// COMMENTS
const { isCommentable } = storeToRefs(useSurfaceSettingsStore())
const { commentEntities } = storeToRefs(useCommentsStore())
const { fetchComments } = useCommentsStore()

const wallComments = computed((): Record<Id, Comment> => {
  return commentEntities.value
})

const xComments = computed((): boolean => {
  if (isScreenshotMode.value && !xCommentsInScreenshotMode.value) return false
  // Turn comments off when switching to Backchannel
  return props.post.published === true && (isCommentable.value || props.postCommentIds.length > 0)
})

// ATTACHMENT
const xEmbedControl = ref(false)
const embedData = ref<{
  downloadUrl?: string
  isDownloadable?: boolean
}>({})
const embedControlPositionStyle = ref<{ top?: string }>({})
let embedControlRepositionObserver: any | null = null

const shouldSyncEmbedControlSize = computed((): boolean => {
  return xEmbedControl.value && !isWishContentScrollable.value && !!props.post.attachment
})

watch(shouldSyncEmbedControlSize, (newValue) => {
  // disconnect any existing control observer before setting a new observer
  embedControlRepositionObserver?.disconnect()
  embedControlRepositionObserver = null
  if (newValue) {
    if (postContentRef.value === undefined) return
    const observer = observeSizeChange(postContentRef.value, calculateEmbedControlPosition)
    if (!observer) return
    if (beforeAttachmentRef.value === undefined) return
    observer.observe(beforeAttachmentRef.value)
    postBannerRef.value && observer.observe(postBannerRef.value)
    embedControlRepositionObserver = observer
  }
})

/**
 * When attachment has an inline embed (e.g. Youtube, Spotify), SurfacePostAttachment will handle rendering the embed inline
 * while the embed control will be rendered here.
 * We need to calculate embed control position and recalculate if any post content is resized with ResizeObserver.
 */
function toggleEmbedControl({ xEmbed, downloadUrl, isDownloadable }): void {
  embedData.value = { downloadUrl, isDownloadable }
  xEmbedControl.value = xEmbed
}

function postMessageToAttachment(event: AttachmentPostMessageEvent): void {
  attachmentRef.value?.postMessage(event)
}
function expand(post: Post): void {
  postMessageToAttachment('back-to-preview')
  expandPost({ postCid: post.cid, xTextPanel: !isSmallerThanDesktop.value })
}

function calculateEmbedControlPosition(): void {
  nextTick(() => {
    if (!shouldSyncEmbedControlSize.value) return
    const embedWrapperRef = attachmentRef.value?.$refs?.embedWrapper as HTMLDivElement | undefined
    if (!embedWrapperRef || !attachmentRef.value) return
    const DISTANCE_TO_EMBED = 8
    const embedHeight = embedWrapperRef.offsetHeight
    const margins =
      DISTANCE_TO_EMBED +
      parseInt(window.getComputedStyle(userContentRef.value as HTMLDivElement).paddingTop, 10) +
      parseInt(window.getComputedStyle(attachmentRef.value.$el).marginTop, 10)
    const awaitingApprovalHeight = postBannerRef.value?.offsetHeight || 0
    const beforeAttachmentHeight = beforeAttachmentRef.value?.offsetHeight || 0
    const postContentHeight = postContentRef.value?.offsetHeight || 0
    embedControlPositionStyle.value = {
      top: -(postContentHeight - embedHeight - beforeAttachmentHeight - awaitingApprovalHeight - margins) + 'px',
    }
  })
}

// **** PERMISSION-RELATED SHOW ATTRIBUTES ****
const { canIModerate, canIComment, canIWrite } = storeToRefs(useSurfacePermissionsStore())

const wasWrittenByCurrentUser = computed((): boolean => {
  return !!user.value.id && props.post.author_id === user.value.id
})

const isEditableByCurrentUser = computed((): boolean => {
  return canIModerate.value || (wasWrittenByCurrentUser.value && canIWrite.value)
})

// **** INTERACTIONS ****
// Post
const { expandPost } = useExpandedPostStore()
const { showPostActionMenu, setPostActionMenuTriggerRect, setPostRightClickCoordinates } = useSurfacePostActionStore()
const { startEditingPost } = useSurfacePostsStore()
const isMobile = device.mobile

function tryShowActions(e: MouseEvent): void {
  if (
    isMobile ||
    xConnect.value ||
    ['a', 'textarea', 'input', 'select', 'option'].includes((e.target as Element).tagName?.toLowerCase() || '') ||
    e.shiftKey
  ) {
    return
  }
  e.preventDefault()
  setPostRightClickCoordinates({ postRightClickCoordinates: [e.pageX, e.pageY] })
  showPostActionMenu({ postCid: props.post.cid })
}

function showPostMoreActionsDropdown(triggerRect: DOMRect) {
  setPostActionMenuTriggerRect({ postActionMenuTriggerRect: triggerRect })
  showPostActionMenu({ postCid: props.post.cid })
}

function tryStartEditPost(): void {
  if (isFrozen.value) return
  if (isEditableByCurrentUser.value) {
    startEditingPost({ postCid: props.post.cid })
  }
}

const { openConfirmationDialog } = useGlobalConfirmationDialogStore()

// Comment
const { stopEditingComment, showCommentActionMenu, createCommentV2, editCommentV2 } = useCommentsStore()

// **** POST APPROVAL ****
const { approvePost } = useSurfacePostActionStore()
const { rejectPost } = useSurfacePostsStore()
const isPostNotApproved = computed((): boolean => {
  return !props.post.published
})
const postBannerBgColorClass = computed((): string => {
  return postBannerBackgroundColorTailwindClass({
    postColor: displayedPostColor.value,
    isLightColorScheme: !isPadletColorSchemeDark.value,
  })
})

function confirmModerationReject(): void {
  openConfirmationDialog({
    ...CONFIRM_MODERATION_REJECT_POST,
    afterConfirmActions: [() => rejectPost(props.post.id, props.post.hashid)],
    xShadow: true,
  })
}

// KEEP TRACK OF ITS OWN CLIENT WIDTH
const clientWidth = ref(0)
let resizeObserver: ResizeObserver | null = null

function observeResize(): void {
  resizeObserver = observeSizeChange(
    surfacePostBeingShowedRef.value as HTMLElement,
    debounce((_, rect: DOMRectReadOnly) => {
      clientWidth.value = rect.width
    }, 100),
  )
}

function unobserveResize(): void {
  resizeObserver?.disconnect()
  resizeObserver = null
}

const displayedPostWidth = computed((): number => {
  const variableWidth = Math.ceil(clientWidth.value)
  switch (format.value) {
    case 'stream': {
      const MAX_STREAM_POST_WIDTH = isPostSizeWide.value ? 748 : 540
      // See SurfacePostsStream.vue
      if (windowWidth.value <= BreakPoints.TabletPortrait) {
        return variableWidth
      }
      return MAX_STREAM_POST_WIDTH
    }
    case 'grid': {
      return variableWidth
    }
    case 'matrix': {
      return variableWidth
    }
    case 'free': {
      return props.post.width || NON_STREAM_STANDARD_MAX_POST_SIZE
    }
    case 'timeline_v2': {
      const MAX_TIMELINE_V2_POST_WIDTH = isPostSizeWide.value
        ? NON_STREAM_WIDE_MAX_POST_SIZE
        : NON_STREAM_STANDARD_MAX_POST_SIZE
      return MAX_TIMELINE_V2_POST_WIDTH
    }
    case 'map': {
      const MAX_MAP_POST_WIDTH = isPostSizeWide.value
        ? NON_STREAM_WIDE_MAX_POST_SIZE
        : NON_STREAM_STANDARD_MAX_POST_SIZE
      return MAX_MAP_POST_WIDTH
    }
    default: {
      return FIXED_POST_WIDTH[format.value] ?? (Math.ceil(props.post.width || 0) || 1920)
    }
  }
})

function saveEditComment({ id, body, attachment }: { id: Id; body?: string; attachment?: string }): void {
  editCommentV2({ id, htmlBody: body, attachment })
}

function publishComment({ postId, body, attachment }: { postId: Id; body?: string; attachment?: string }): void {
  createCommentV2({
    postId,
    htmlBody: body,
    attachment,
  })
}

// COMPONENT LIFECYCLE
let expandPostOnSingleClick = (): void => {}
onMounted(() => {
  if (isAppUsing('surfaceCommentsPerPost')) {
    const isDisplayedInIframe = window.self !== window.top
    const isIframeInSafari = isDisplayedInIframe && (device.safari || device.ios)

    if (canObserveIntersection && !isIframeInSafari) {
      observeElementIntersection(surfacePostBeingShowedRef.value as HTMLElement, () => {
        if (props.post.id && props.post.hashid) {
          fetchComments({ wishId: props.post.id, wishHashid: props.post.hashid })
        }
      })
    } else {
      if (props.post.id && props.post.hashid) {
        fetchComments({ wishId: props.post.id, wishHashid: props.post.hashid })
      }
    }
  }

  expandPostOnSingleClick = makeSingleClickOnlyHandler(
    () => {
      expand(props.post)
    },
    {
      skipIf: (e) => {
        // Prevent expanding post when it's being dragged
        return (
          (e.target instanceof HTMLElement && e.target.closest('.surface-post')?.classList?.contains('noclick')) ??
          false
        )
      },
    },
  )

  clientWidth.value = surfacePostBeingShowedRef.value?.offsetWidth ?? 0
  // If posts in this format have fixed width, no need to observe resize
  if (!(format.value in FIXED_POST_WIDTH)) {
    observeResize()
  }
})

// Attachment Focus
const xPostMoreActionsFillerElements = ref(true)

onBeforeUnmount(() => {
  unobserveResize()
})
</script>

<template>
  <article
    ref="surfacePostBeingShowedRef"
    :dir="dir()"
    :class="[postBannerBgColorClass, isStream ? 'rounded-2xl' : 'rounded-xl', isCanvas && 'fix-click-on-touch']"
    data-testid="surfacePost"
    tabindex="0"
    @focus="$emit('focus')"
  >
    <!-- Post Banner -->
    <div
      v-if="xPostBanner"
      ref="postBannerRef"
      data-testid="postBanner"
      :class="[
        isPadletColorSchemeDark ? 'text-light-text-100' : 'text-dark-text-100',
        'text-body-small-posts',
        'font-sans',
        'flex',
        'items-center',
        isStream ? 'h-10 ps-4' : 'h-9 ps-3',
      ]"
      @contextmenu="tryShowActions"
    >
      <!-- If a post is scheduled and will also needs to be approved later, we prioritize showing the scheduled state. -->
      <!-- Scheduled banner -->
      <div
        v-if="isScheduled"
        :class="['flex flex-row gap-1', isPadletColorSchemeDark ? 'text-light-text-200' : 'text-dark-text-200']"
        data-testid="postScheduleDisplay"
      >
        <OzIcon :name="'recent_outline'" :size="16" />
        <time class="text-body-extra-small" :title="scheduledAtDateString" :datetime="post.scheduled_at">
          {{
            __('{date} at {time}', {
              date: formattedScheduledDateAndTime.date,
              time: formattedScheduledDateAndTime.time,
            })
          }}
        </time>
      </div>

      <!-- Submitted banner -->
      <span v-else-if="isAwaitingApproval" class="italic">
        {{ __('Awaiting approval') }}
      </span>

      <!-- Pinned banner -->
      <div
        v-else-if="xPin"
        :class="['flex items-center gap-2', isPadletColorSchemeDark ? 'text-light-text-200' : 'text-dark-text-200']"
      >
        <OzIcon :name="'pin_filled'" :size="16" />
        <span class="text-body-small">{{ __('Pinned') }}</span>
      </div>
    </div>

    <!-- Post content -->
    <div
      :class="[
        'wish-content',
        'relative',
        tailwindFontClass,
        {
          'h-full': fitHeightToContainer,
          'rounded-2xl': isStream,
          'rounded-xl': !isStream,
        },
      ]"
      :data-index="index + 1"
    >
      <div
        ref="postContentRef"
        :class="[
          postColorClass,
          {
            'map-wish': isMap,
            'timeline-wish flex flex-col': isTimeline,
            'unapproved-wish': isPostNotApproved,
            'overflow-hidden': true,
            'h-full': fitHeightToContainer,
            'flex flex-col items-stretch justify-center': verticallyCenterContent,
            'rounded-2xl': isStream,
            'rounded-xl': !isStream,
          },
        ]"
        :style="getOverflowBorderRadiusTransformFixStyle"
        :data-post-color="displayedPostColor || 'default'"
        @contextmenu="tryShowActions"
      >
        <SurfacePostMoreActions
          v-if="!isWishContentScrollable && !isDemoPadletPanelDesktop"
          :more-actions-button-classes="{
            'me-0.5': isStream && isPostSizeWide,
          }"
          :x-overlay="xOverlayForMoreButtons"
          :post="post"
          :color-scheme="colorScheme"
          :background-color="displayedPostColor"
          :x-filler-elements="xPostMoreActionsFillerElements"
          @edit="startEditingPost({ postCid: post.cid })"
          @dropdown="showPostMoreActionsDropdown"
        />
        <!-- User content, scrollable in timeline and map -->
        <div
          :class="{
            'map-wish': isMap && !xSocialContent,
            'map-wish-with-stalker-bar': isMap && xSocialContent,
            'overflow-x-hidden': isWishContentScrollable,
            'overflow-y-auto': isWishContentScrollable,
            'scrollbar-regular': colorScheme === 'light',
            'scrollbar-regular-dark': colorScheme === 'dark',
          }"
        >
          <!-- Above: Wrapper for scrollbar, below: wrapper for content -->
          <div
            ref="userContentRef"
            :class="[
              isStream && {
                'pb-1': xPostProperties,
                'pb-2': !xPostProperties && xBody,
                'pb-3': !xPostProperties && !xBody,
                'pt-3': isPostSizeStandard || isPostSizeWide,
                'px-3': isPostSizeStandard,
                'px-3.5': isPostSizeWide,
              },
              !isStream && {
                'pt-2 px-2': true,
                'pb-1': xPostProperties,
                'pb-2': !xPostProperties && (xBody || xAttachment),
                'pb-2.5': !xPostProperties && !xBody && !xAttachment && xSubject,
              },
            ]"
          >
            <!-- Author, Subject, attachment and body -->
            <div ref="beforeAttachmentRef">
              <!-- Author -->
              <div v-if="xAuthor" class="flex" @dblclick="tryStartEditPost">
                <SurfacePostAuthor
                  data-testid="surfacePostAuthor"
                  :post="post"
                  :avatar-size="xRoleBadge && !isStream ? 24 : isStream ? 20 : 16"
                  :class="{
                    'px-1': true,
                    grow: true,
                    'min-w-0': true,
                  }"
                />
                <div class="w-4 h-1 shrink-0" />
              </div>
              <!-- All formats -->
              <!-- N.B: invisible floating div for text to avoid the more action buttons -->
              <!-- goodcheck-disable-next-line -->
              <div v-if="xInvisibleFloatingDiv" class="ltr:float-right rtl:float-left w-4 h-5" />

              <!-- h3 must be a child of h2 in the DOM; h2 only exists when sections are enabled  -->
              <component
                :is="isSectioned ? 'h3' : 'h2'"
                v-if="subject"
                dir="auto"
                :class="{
                  'text-light-text-100': colorScheme === 'dark',
                  'text-dark-text-100': colorScheme === 'light',
                  'text-17': !isStream,
                  'text-heading-3': isStream,
                  'font-semibold': true,
                  'px-1': true,
                  'mt-2': !isStream && xAuthor,
                  'mt-3': isStream && xAuthor,
                  'break-words': true,
                  'whitespace-break-spaces': true,
                }"
                style="word-break: break-word"
                data-testid="postSubject"
                data-pw="postSubject"
                @dblclick="tryStartEditPost"
                >{{ subject }}</component
              >
            </div>
            <!-- N.b. Set key so that when the attachment changes, the attachment is reloaded -->
            <SurfacePostAttachment
              v-if="xAttachment"
              ref="attachmentRef"
              :key="post.attachment"
              :class="{
                'mt-3': isStream && (xAuthor || xSubject),
                'mt-1.5': !isStream && xSubject,
                'mt-2': !isStream && !xSubject && xAuthor,
              }"
              :dir="dir()"
              :post="post"
              :displayed-post-width="displayedPostWidth"
              :is-editable-by-current-user="isEditableByCurrentUser"
              @toggle-embed-control="toggleEmbedControl"
              @contextmenu.native.prevent
              @focus-attachment="xPostMoreActionsFillerElements = false"
              @blur-attachment="xPostMoreActionsFillerElements = true"
            />

            <SurfacePostPoll
              v-if="xPoll"
              :class="[
                // if there is an attachment or subject, bottom padding is added by https://github.com/padlet/mozart/blob/a5c65aff0c3b9ee2669805954322d42577b60e22/services/rails/app/javascript/vuecomponents/SurfacePostBeingShowed.vue#L106
                // so dont need to add more bottom margin here
                !isStream && !xAttachment && !xSubject && 'mb-2',
                !xOverlayForMoreButtons && 'mt-1.5',
              ]"
              :poll="poll"
              :poll-caption="post.attachment_caption"
              :post="post"
              :user="user"
              :dark-mode="colorScheme === 'dark'"
              :background-color="displayedPostColor"
              @dblclick="tryStartEditPost"
              @click="expandPostOnSingleClick"
              @enter="expand(post)"
            />
            <SurfacePostMoreActions
              v-if="isWishContentScrollable && !isDemoPadletPanelDesktop"
              :post="post"
              :color-scheme="colorScheme"
              :background-color="displayedPostColor"
              @edit="startEditingPost({ postCid: post.cid })"
              @dropdown="showPostMoreActionsDropdown"
            />
            <SurfacePostBody
              v-if="xBody"
              :content="body"
              :dark-mode="colorScheme === 'dark'"
              :class="{
                'wish-body-content-stream': isStream,
                'text-14': !isStream,
                'text-body-posts': isStream,
                'px-1': true,
                'pt-1.5': xSubject && !xAttachment,
                'pt-2': (!xSubject && xAuthor) || xAttachment,
                'pb-1': true,
              }"
              :data-color="displayedPostColor"
              :is-text-selectable="false"
              @dblclick="tryStartEditPost"
            />
            <SurfacePostProperties
              v-if="xPostProperties"
              :class="['p-1', !xSubject && !xAttachment && !xBody && !xAuthor && 'pt-0']"
              :dark-mode="colorScheme === 'dark'"
              :location-name="isMap || isLocationEnabled ? post.location_name : undefined"
              :wall-custom-post-properties="wallCustomPostProperties"
              :custom-property-values="post.custom_properties"
              :link-button-size="isStream ? 32 : 24"
              :post-color="post.color"
              :color-scheme="colorScheme"
              @dblclick="tryStartEditPost"
            />
          </div>
        </div>

        <!-- Content separator -->
        <div
          v-if="xSocialContent"
          :class="[
            isPadletColorSchemeDark ? 'border-light-text-400' : 'border-dark-text-400',
            'border-solid',
            'border-t-0.5',
            isStream ? 'mx-3' : 'mx-2',
          ]"
        />

        <!-- Social content -->
        <section
          v-if="xSocialContent"
          :class="[
            {
              'box-border flex flex-row w-full': !xCommentsUnderPosts,
            },
          ]"
        >
          <SurfacePostSocialContentTopBar
            v-if="xPostSocialContentTopBar"
            :class="{
              'pt-2': !isStream,
              'pt-3': isStream,
              'px-3': !isStream,
              'px-4': isStream,
              'pb-3': !xComments || !xCommentsUnderPosts,
            }"
            :post="post"
            :post-reaction-ids="postReactionIds"
            :post-comment-ids="postCommentIds"
          />

          <SurfacePostComments
            v-if="xComments && xCommentsUnderPosts"
            data-testid="surfacePostComments"
            :class="{ 'mt-4': xReactions && postCommentIds.length > 0 }"
            :post="post"
            :user="user"
            :comment-ids="postCommentIds"
            :comments="wallComments"
            :can-add-comment="isCommentable && canIComment"
            :displayed-post-width="displayedPostWidth"
            @create="publishComment($event)"
            @show-comment-action-menu="showCommentActionMenu($event)"
            @stop-editing="stopEditingComment($event)"
            @edit="saveEditComment($event)"
          />
          <!-- keep comment icon on the right when there is no reaction button-->
        </section>
      </div>

      <div v-if="xEmbedControl" :class="['h-0', 'absolute', 'inset-x-2']">
        <SurfacePostAttachmentEmbedControl
          class="absolute -bottom-3 inset-x-0"
          :dir="dir()"
          :style="embedControlPositionStyle"
          :color-scheme="colorScheme"
          :is-downloadable="embedData.isDownloadable"
          :download-url="embedData.downloadUrl"
          data-testid="surfacePostAttachmentEmbedControl"
          @back-to-preview="postMessageToAttachment('back-to-preview')"
          @zoom="postMessageToAttachment('zoom')"
          @expand="expand(post)"
          @open-in-new="postMessageToAttachment('open-in-new')"
        />
      </div>
    </div>

    <!-- Post approve/remove -->
    <SurfacePostModeration
      v-if="!isScheduled && isPostNotApproved && canIModerate"
      class="shrink-0"
      :color-scheme="colorScheme"
      :moderation-violations="post.moderation_violations"
      @approve="approvePost({ postCid: post.cid })"
      @reject="confirmModerationReject"
    />
  </article>
</template>

<style lang="postcss" scoped>
.wish-shadow {
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.12);
}
.wish-bigger-shadow {
  box-shadow: 0 3px 3px rgba(0, 0, 0, 0.12), 0px 2px 2px rgba(0, 0, 0, 0.16);
}

:deep(.wish-body-content-stream) {
  pre {
    @apply my-2;
    @apply py-2;
    @apply px-3;
  }
}
</style>
