//@file Draggable Mixin for draggable posts in surface_posts.vue
import { $ } from '@@/bits/jquery_ui_draggable'
import { getJsPlumbInstance } from '@@/bits/jsplumb'
import { mapActions } from 'vuex'
export default {
  beforeDestroy() {
    this.destroyDraggable()
  },
  methods: {
    ...mapActions(['changeDragging']),
    initializeDraggable(onMovedPostHandler) {
      this.$_draggableMixin_onMovedPostHandler = onMovedPostHandler
      this.$nextTick(() => {
        this.$_draggableMixin_makeDraggable()
      })
    },
    destroyDraggable() {
      this.$_draggableMixin_destroyDraggable()
      this.$_draggableMixin_onMovedPostHandler = null
    },
    $_draggableMixin_savePosition(position) {
      this.$_draggableMixin_onMovedPostHandler({
        postCid: this.post.cid,
        position: position,
      })
    },
    $_draggableMixin_makeDraggable() {
      let rootElement = $(this.$el)
      rootElement.draggable({
        containment: [0, 0],
        cancel:
          '[contenteditable], .ui-resizable-handle, object, embed, input, textarea, button, select, option, .no-drag-canvas',
        cursor: 'move',
        snap: '.surface-post',
        snapTolerance: 4,
        start: (event, ui) => {
          var boundingRect
          rootElement.addClass('noclick is-moving')
          boundingRect = this.$el.parentElement.getBoundingClientRect()
          rootElement.draggable('option', 'containment', [boundingRect.left, boundingRect.top])
          rootElement.draggable('instance')._setContainment()
          this.changeDragging({ isAPostDragging: true })
        },
        drag: (event, ui) => {
          getJsPlumbInstance()?.revalidate(this.$el)
        },
        stop: (event, ui) => {
          setTimeout(() => {
            rootElement.removeClass('noclick is-moving')
          }, 100)
          this.$_draggableMixin_savePosition(ui.position)
          this.changeDragging({ isAPostDragging: false })
        },
      })
    },
    $_draggableMixin_destroyDraggable() {
      if (!this.$el) return
      const draggableInstance = $(this.$el).off('.draggable').draggable('instance')
      if (draggableInstance) draggableInstance.destroy()
    },
  },
}
