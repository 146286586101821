<script setup lang="ts">
import OzContainedButton, { OzContainedButtonSizePreset } from '@@/library/v4/components/OzContainedButton.vue'
import OzInput, { OzInputSizePreset } from '@@/library/v4/components/OzInput.vue'
import { useDarkModeStore } from '@@/pinia/dark_mode'
import { computed, ref } from 'vue'

defineProps<{
  xConnect?: boolean
  isStream?: boolean
}>()

defineEmits<{ (e: 'connect', label: string): void }>()

const tipColor = computed(() => {
  return useDarkModeStore().isDarkMode ? 'rgb(254, 192, 6, 0.06)' : 'rgb(148, 102, 232, 0.1)' // rgb values are taken from the tailwind bg classes below
})

const label = ref('')
</script>
<template>
  <div>
    <form
      class="flex flex-col justify-center items-center absolute inset-0 px-3.5 gap-2 bg-grape-500/10 dark:bg-canary-500/[0.06]"
      :class="isStream ? 'rounded-2xl' : 'rounded-xl'"
      @submit.prevent="$emit('connect', label)"
    >
      <OzInput
        class="flex-none w-full bg-light-ui-100 dark:bg-dark-ui-100"
        maxlength="50"
        :placeholder="__('Label (optional)')"
        :aria-label="__('Connection label')"
        :size-preset="OzInputSizePreset.H40px"
        :model-value="label"
        :test-id="'postConnectionLabelInput'"
        @update:modelValue="label = $event"
      />
      <OzContainedButton
        class="w-full"
        type="submit"
        :text="__('Connect')"
        data-testid="postConnectButton"
        :size-preset="OzContainedButtonSizePreset.H40px"
      />
    </form>
    <!-- The tip -->
    <svg
      class="popup-bubble-anchor-tip z-10"
      width="55"
      height="16"
      viewBox="0 0 55 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.00219655 0C6.36739 0 12.4719 2.52856 16.9727 7.02944L24.1939 14.2506C25.756 15.8127 28.2887 15.8127 29.8508 14.2506L37.072 7.02943C41.5729 2.52856 47.6774 -1.32551e-06 54.0426 0C54.0996 1.18735e-08 -0.398293 0 0.00219655 0Z"
        :fill="tipColor"
      />
    </svg>
  </div>
</template>
