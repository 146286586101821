<script setup lang="ts">
import { observeElementIntersection } from '@@/bits/intersection'
import { requestIdleCallback } from '@@/bits/request_idle_callback'
import { onMounted, ref } from 'vue'

const props = withDefaults(
  defineProps<{
    tag?: string
    minHeight: number
    minWidth: number
    renderImmediately?: boolean
  }>(),
  {
    tag: 'div',
    renderImmediately: false,
  },
)

const isVisible = ref(props.renderImmediately)
const rootElement = ref<HTMLElement | null>(null)

onMounted(() => {
  if (isVisible.value) return
  requestIdleCallback(() => {
    if (rootElement.value === null) return
    observeElementIntersection(rootElement.value, () => {
      requestIdleCallback(() => {
        isVisible.value = true
      })
    })
  })
})
</script>

<template>
  <component
    :is="tag"
    ref="rootElement"
    :style="[
      // Before the children is render, we need to set the minHeight and minWidth
      // in order for the intersectionObserver to take into consideration the minimum size of the children
      !isVisible && {
        minHeight: `${minHeight}px`,
        minWidth: `${minWidth}px`,
      },
    ]"
  >
    <slot v-if="isVisible"></slot>
  </component>
</template>
