<template>
  <SurfaceLazy
    v-if="lazyRender"
    :min-height="minPostHeight"
    :min-width="minPostWidth"
    :id="'wish-' + (post.id || 'new')"
    :class="classes"
    :style="styles"
    :data-view-mode="'show'"
    :data-post-cid="post.cid"
    :data-pinned="String(!!post.pinned_at)"
    :data-rank="post.sort_index"
    :data-index="index + 1"
    :data-section-id="post.wall_section_id"
    :data-color="post.color"
  >
    <template>
      <SurfacePostBeingShowed v-bind="$props" />
      <PostConnectionOverlay v-if="xConnectAndShowNewOverlay" @connect="handleConnect" />
      <div v-else-if="xConnect" class="wish-selection">
        <form @submit.prevent="selectToConnect">
          <input
            ref="connectorLabelInput"
            :aria-label="__('Connector label')"
            maxlength="50"
            data-testid="postConnectionLabelInput"
            :placeholder="__('Label (optional)')"
          />
          <button class="raised-button" type="submit" data-testid="postConnectButton">{{ __('Connect') }}</button>
        </form>
      </div>
    </template>
  </SurfaceLazy>
  <div
    v-else
    :id="'wish-' + (post.id || 'new')"
    :class="classes"
    :style="styles"
    :data-view-mode="'show'"
    :data-post-cid="post.cid"
    :data-pinned="String(!!post.pinned_at)"
    :data-rank="post.sort_index"
    :data-index="index + 1"
    :data-section-id="post.wall_section_id"
    :data-color="post.color"
  >
    <SurfacePostBeingShowed v-bind="$props" @focus="$emit('focus')" />
    <PostConnectionOverlay v-if="xConnectAndShowNewOverlay" @connect="handleConnect" />
    <div v-else-if="xConnect" class="wish-selection">
      <form @submit.prevent="selectToConnect">
        <input
          ref="connectorLabelInput"
          :aria-label="__('Connector label')"
          maxlength="50"
          data-testid="postConnectionLabelInput"
          :placeholder="__('Label (optional)')"
        />
        <button class="raised-button" type="submit" data-testid="postConnectButton">{{ __('Connect') }}</button>
      </form>
    </div>
  </div>
</template>

<script>
/* eslint-disable @typescript-eslint/explicit-function-return-type */

/**
 * This component will render either SurfacePostBeingShowed or SurfacePostBeingEdited depend
 * on the if the post is being edited.
 * The two inner components should have the logic and vuex actions that related to the post
 * and interactions on the post itself:
 * - modify content of the post (including reactions, comments, moderation)
 * - change the toggle status of panels and dropdowns
 * This component should only have the logic and vuex actions that related to post appearance
 * and the interactions between posts:
 * - decide classes and styles
 * - modify size/position of post
 * - modify connections for freeform format
 */
import { isUrl } from '@@/bits/url'
import { useSurfaceMapStore } from '@@/pinia/surface_map'
import { useSurfacePostActionStore } from '@@/pinia/surface_post_action'
import { useSurfacePostConnectionStore } from '@@/pinia/surface_post_connection'
import { useReactionsStore } from '@@/pinia/surface_reactions'
import SurfacePostBeingShowed from '@@/vuecomponents/SurfacePostBeingShowed.vue'
import DraggableMixin from '@@/vuemixins/draggable_mixin'
import ResizableMixin from '@@/vuemixins/resizable_mixin'
import { mapState as piniaMapState } from 'pinia'
import { mapActions, mapGetters, mapState } from 'vuex'
import SurfaceLazy from '@@/vuecomponents/SurfaceLazy.vue'
import { isAppUsing } from '@@/bits/flip'
import PostConnectionOverlay from '@@/vuecomponents/PostConnectionOverlay.vue'

export default {
  components: {
    SurfacePostBeingShowed,
    SurfaceLazy,
    PostConnectionOverlay,
  },
  mixins: [DraggableMixin, ResizableMixin],
  props: {
    index: Number,
    post: { type: Object, default: () => ({}) },
    isLast: { type: Boolean, default: false },
    putAttachmentOnTop: { type: Boolean, default: false },
    fitHeightToContainer: { type: Boolean, default: false },
    verticallyCenterContent: { type: Boolean, default: false },
    xShadow: { type: Boolean, default: true },
    postReactionIds: { type: Array, default: () => [] },
    postCommentIds: { type: Array, default: () => [] },
    lazyRender: { type: Boolean, default: false },
    minPostHeight: { type: Number, default: 0 },
    minPostWidth: { type: Number, default: 0 },
  },
  data() {
    return {
      label: '',
    }
  },
  computed: {
    ...mapState(['user', 'isMousable', 'canPostMessage']),
    ...piniaMapState(useSurfacePostActionStore, [
      'postUnderActionCid',
      'postBeingTransferredCid',
      'postBeingCopiedCid',
    ]),
    ...piniaMapState(useReactionsStore, ['postIdBeingReactedTo']),
    ...piniaMapState(useSurfacePostConnectionStore, ['connections', 'postToConnectFromId']),
    ...mapGetters(['canIModerate', 'canIWrite', 'format', 'xAuthor', 'xNewComposerModalPanel', 'isStream', 'isApp']),
    ...piniaMapState(useSurfaceMapStore, ['postWithLocationBeingEditedCid']),
    isBeingActedUpon() {
      return (
        this.postBeingTransferredCid === this.post.cid ||
        this.postBeingCopiedCid === this.post.cid ||
        this.postToConnectFromId === this.post.id ||
        this.postWithLocationBeingEditedCid === this.post.cid
      )
    },
    isBeingReacted() {
      return this.postIdBeingReactedTo === this.post.id
    },
    isAppActingOnPost() {
      return this.isApp && this.canPostMessage && !!this.postUnderActionCid && this.post.id === this.postUnderActionCid
    },
    wasWrittenByCurrentUser() {
      return !!this.user.id && this.post.author_id == this.user.id
    },
    isEditableByCurrentUser() {
      return this.canIModerate || (this.wasWrittenByCurrentUser && this.canIWrite)
    },
    hasAttachment() {
      const attachment = this.post.attachment
      return !!attachment && isUrl(attachment)
    },
    xConnect() {
      return (
        !!this.postToConnectFromId &&
        this.postToConnectFromId !== this.post.id &&
        !this.areConnected(this.post.id, this.postToConnectFromId)
      )
    },
    xConnectAndShowNewOverlay() {
      return this.xConnect && this.isAppUsing('locationSelectorV2')
    },
    styles() {
      const styles = {}
      if (this.format === 'free') {
        if (this.isBeingActedUpon || this.isAppActingOnPost || this.isBeingReacted) {
          styles['z-index'] = 12000
        } else {
          styles['z-index'] = 10000 - this.index
        }
        // handle size
        const postWidth = this.post.width || 256
        styles.width = postWidth + 'px'
        // handle positioning
        const postTop = this.post.top !== null ? this.post.top : 16 * this.index
        const postLeft = this.post.left !== null ? this.post.left : 16 * this.index
        styles.top = postTop + 'px'
        styles.left = postLeft + 'px'
      }
      return styles
    },
    classes() {
      return {
        'surface-post': true,
        wish: true,
        'select-none': true,
        // this class must match the border radius class used for SurfacePostBeingShowed
        'rounded-2xl': this.isStream,
        'rounded-xl': !this.isStream,
        'is-animated': this.isBeingActedUpon,
        pulsate: this.isBeingActedUpon || this.isAppActingOnPost,
        'wish-editable': this.isEditableByCurrentUser,
        'wish-mine': this.wasWrittenByCurrentUser,
        'with-attachment': this.hasAttachment,
        'with-author': this.xAuthor,
        'attachment-on-top': this.putAttachmentOnTop,
        'full-height': this.fitHeightToContainer,
        'shadow-elevation-1': this.xShadow,
      }
    },
  },
  watch: {
    isEditableByCurrentUser(newValue) {
      if (this.format === 'free' && newValue) {
        this.initializeResizable(this.isMousable, this.resizedPost)
      } else {
        this.destroyResizable()
      }
    },
  },
  mounted() {
    if (this.format === 'free' && this.isEditableByCurrentUser) {
      this.initializeResizable(this.isMousable, this.resizedPost)
      this.initializeDraggable(this.movedPost)
    } else {
      this.destroyResizable()
      this.destroyDraggable()
    }
    if (this.isLast) {
      this.$emit('last-post-mounted')
    }
  },
  methods: {
    isAppUsing: isAppUsing,
    ...mapActions('post', ['movedPost', 'resizedPost']),
    areConnected(post1Id, post2Id) {
      return (
        this.connections.filter((c) => {
          return (
            (c.from_wish_id === post1Id && c.to_wish_id === post2Id) ||
            (c.from_wish_id === post2Id && c.to_wish_id === post1Id)
          )
        }).length > 0
      )
    },
    handleConnect(label) {
      this.label = label
      this.selectToConnect()
    },
    selectToConnect() {
      const label = isAppUsing('locationSelectorV2') ? this.label : this.$refs.connectorLabelInput.value
      if (this.postToConnectFromId != null) {
        useSurfacePostConnectionStore().completeConnection({ postToConnectToId: this.post.id, label })
      }
    },
  },
}
</script>

<style lang="scss">
.attachment-on-top {
  .real-wish {
    display: flex;
    flex-direction: column;

    .surface-post-attachment {
      order: -1;
      flex-shrink: 0;
    }
    .surface-post-author {
      order: -2;
      flex-shrink: 0;
    }
    .words {
      flex-grow: 1;
    }
  }
}

.attachment-on-top.with-attachment {
  .real-wish {
    .author + .words {
      padding-top: 12px;
    }
  }
}
</style>

<style lang="scss" scoped>
.full-height {
  height: 100%;
}

// need this so dragging on fire won't leave a weird trail: https://www.loom.com/share/e5f2cd1ea25d4021b7d2c20559e1318d
// looks like it's caused by rotating the container without rotating the child so the child is rendered in a weird way
// => rotate the first child slightly so it will be rendered together with the container
.wish.ui-sortable-helper {
  .firefox & {
    & > :first-child {
      transform: rotate(0.1deg);
    }
  }
}
</style>
