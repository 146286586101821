// @file Helper for click events.
import { captureException } from '@@/bits/error_tracker'

type MouseEventHandler = (e: MouseEvent) => void

const DEFAULT_DBLCLICK_DELAY_MS = 300

/**
 * Makes a click event handler that only runs if there is a single click
 * within a certain delay. The default delay is 300ms.
 * @param handler The event handler function.
 * @param options.delayMs (Optional) The delay in milliseconds.
 * @param options.skipIf (Optional) A function that returns true if the event should be skipped.
 * @returns A new function that only runs if it's called once within the delay.
 */
export function makeSingleClickOnlyHandler(
  handler: MouseEventHandler,
  options?: { delayMs?: number; skipIf?: (e: MouseEvent) => boolean },
): MouseEventHandler {
  let timer
  let clicks = 0

  const reset = (): void => {
    clearTimeout(timer)
    timer = null
    clicks = 0
  }

  return (e) => {
    if (options?.skipIf?.(e) ?? false) return
    clicks++
    if (clicks === 1) {
      timer = setTimeout(() => {
        try {
          handler(e)
        } catch (e) {
          captureException(e)
        } finally {
          reset()
        }
      }, options?.delayMs ?? DEFAULT_DBLCLICK_DELAY_MS)
    } else {
      reset()
    }
  }
}
