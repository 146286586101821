<script setup lang="ts">
import device from '@@/bits/device'
import { postColorTailwindClass } from '@@/bits/post_color'
import OzIcon from '@@/library/v4/components/OzIcon.vue'
import OzPlainButton, { OzPlainButtonColorScheme } from '@@/library/v4/components/OzPlainButton.vue'
import { createDomElement } from '@@/native_bridge/ui'
import { useSurfaceStore } from '@@/pinia/surface'
import { useSurfacePermissionsStore } from '@@/pinia/surface_permissions'
import type { Post, PostColor, VueCssClass } from '@@/types'
import emitClickOnTouch from '@@/vuedirectives/emit_click_on_touch'
import { storeToRefs } from 'pinia'
import { computed, ref } from 'vue'

const vEmitClickOnTouch = emitClickOnTouch
const isTouchable = device.touchable

const props = withDefaults(
  defineProps<{
    post: Post
    backgroundColor: PostColor
    colorScheme: 'light' | 'dark'
    xOverlay?: boolean
    /** This prop dictates whether absolutely position filler elements should be shown */
    xFillerElements?: boolean
    /** This prop dictates whether the more actions button should have additional classes */
    moreActionsButtonClasses?: VueCssClass
  }>(),
  {
    xOverlay: true,
    xFillerElements: true,
    moreActionsButtonClasses: undefined,
  },
)

const emit = defineEmits<{
  (name: 'dropdown', rect: DOMRect): void
  (name: 'edit'): void
}>()

const { isStream, isTimeline, isMap, isScreenshotMode, user, isFrozen } = storeToRefs(useSurfaceStore())
const { canIEdit, canIWrite } = storeToRefs(useSurfacePermissionsStore())

const isEditButtonFocused = ref<boolean>(false)
const isMoreActionsButtonFocused = ref<boolean>(false)

/**
 * Only for timeline: the whole post can be scrolled.
 */
const isWishContentScrollable = computed((): boolean => {
  return isTimeline.value || isMap.value
})

const isPostAuthor = computed<boolean>(() => {
  return props.post.author_id === user.value?.id
})

const xEditPost = computed<boolean>(() => {
  if (isFrozen.value || isTouchable) return false
  return canIEdit.value || (canIWrite.value && isPostAuthor.value)
})

const isLightColorScheme = computed<boolean>(() => {
  return props.colorScheme === 'light'
})

const bgColorClass = computed<string>(() => {
  return postColorTailwindClass({ postColor: props.backgroundColor, isLightColorScheme: isLightColorScheme.value })
})

const cornerColorClass = computed<string>(() => {
  switch (props.backgroundColor) {
    case 'red':
      return isLightColorScheme.value ? 'corner-scarlet-100' : 'corner-scarlet-900'
    case 'orange':
      return isLightColorScheme.value ? 'corner-canary-100' : 'corner-tangerine-900'
    case 'green':
      return isLightColorScheme.value ? 'corner-park-100' : 'corner-park-900'
    case 'blue':
      return isLightColorScheme.value ? 'corner-oceanic-100' : 'corner-oceanic-900'
    case 'purple':
      return isLightColorScheme.value ? 'corner-grape-100' : 'corner-grape-900'
    default:
      return isLightColorScheme.value ? 'corner-light-ui-100' : 'corner-dark-ui-100'
  }
})

function onMoreActionsButtonClick(event: Event): void {
  const button = event.currentTarget as HTMLButtonElement

  const buttonRect = createDomElement(button)
  const buttonContentRect = createDomElement(button.firstElementChild as HTMLElement)

  const buttonContentCenter = {
    x: buttonContentRect.x + buttonContentRect.width / 2,
    y: buttonContentRect.y + buttonContentRect.height / 2,
  }

  const topOffset = buttonContentCenter.y - buttonRect.y

  // The trigger rect will be calculated based on the following assumptions:
  // 1. The button content (i.e. the icon) is the visual center of the button
  // 2. The top offset of the button determines the offset from the center of
  //    the visual layout of the button
  // Thus to calculate the right trigger rect we have to:
  // 1. Find the center of the button content
  // 2. Find its offset from the top of the button
  // 3. Extend outwards from the center of the button content in all
  //    directions to obtain the trigger rect

  const minX = buttonContentCenter.x - topOffset
  const minY = buttonRect.y
  const maxX = buttonContentCenter.x + topOffset
  const maxY = buttonContentCenter.y + topOffset
  const triggerRect = new DOMRect(/* x */ minX, /* y */ minY, /* width */ maxX - minX, /* height */ maxY - minY)

  emit('dropdown', triggerRect)
}
</script>

<template>
  <!-- webkit-mask-image is to help with a bug in Safari with border-radius + overflow + transform: https://gist.github.com/ayamflow/b602ab436ac9f05660d9c15190f4fd7b -->
  <div
    v-if="!isScreenshotMode"
    :class="{
      absolute: true,
      'z-10': true,
      'top-0 ltr:right-0 rtl:left-0': true,
      group: true,
      'ltr:rounded-tr-xl rtl:rounded-tl-xl': true,
    }"
  >
    <div>
      <!-- Wrapper for the whole block -->
      <div
        :class="{
          'h-[29px] w-min': true,
          relative: true,
          flex: true,
          'ltr:pl-1 rtl:pr-1': true,
          'overflow-visible': isTouchable,
        }"
      >
        <!-- Sliding div for Edit button and the sliding corner  -->
        <div
          v-if="xEditPost"
          :class="{
            'transition-transform': true,
            'translate-x-0': isEditButtonFocused,
            'ltr:translate-x-full rtl:-translate-x-full group-hover:translate-x-0': !isEditButtonFocused,
            'ltr:rounded-bl-lg rtl:rounded-br-lg': true,
            'ltr:pr-[9px] rtl:pl-[9px]': true,
            'pt-[3px] ltr:mr-[-8px] rtl:ml-[-8px]': true,
            'flex justify-start items-start': true,
            [bgColorClass]: true,
          }"
        >
          <!-- New corner after the animation  -->
          <div
            v-if="xOverlay && !isWishContentScrollable"
            :class="{
              absolute: true,
              'ltr:left-[-4px] rtl:right-[-4px] top-[7px]': !isStream,
              'ltr:left-[-4px] rtl:right-[-4px] top-[11px]': isStream,
              'rtl:-scale-x-100': true,
              [cornerColorClass]: true,
            }"
          />
          <!-- The edit button itself  -->
          <OzPlainButton
            :color-scheme="OzPlainButtonColorScheme.SecondaryIcon"
            :dark-mode="colorScheme === 'dark'"
            :size-preset="null"
            :class="[
              'font-semibold',
              'text-body-extra-small',
              'py-0',
              'px-1.5',
              'h-4',
              'mt-[5px]',
              'mb-[5px]',
              // Focus
              colorScheme === 'light' && 'focus-visible:text-grape-500',
              colorScheme === 'dark' && 'focus-visible:text-canary-500',
            ]"
            :text="__('Edit')"
            :aria-label="__('Edit this post')"
            @click="$emit('edit')"
            @focus="isEditButtonFocused = true"
            @blur="isEditButtonFocused = false"
          />
        </div>
        <!-- Divider between edit and 3 dots button  -->
        <div
          v-if="xEditPost"
          :class="{
            'z-20': true,
            'opacity-0': !xFillerElements || !isEditButtonFocused || isMoreActionsButtonFocused,
            'opacity-100': xFillerElements && isEditButtonFocused,
            'group-hover:opacity-100': xFillerElements && !isEditButtonFocused && !isMoreActionsButtonFocused,
            'transition-opacity': true,
            'h-4 mt-[8px] mb-[5px]': true,
            'w-[0px]': true,
            'border-r-[0.5px] border-solid': true,
            'border-dark-text-400': colorScheme === 'light',
            'border-light-text-400': colorScheme === 'dark',
          }"
        ></div>
        <!-- Div for the 3 dots and the static corner   -->
        <div
          :class="{
            'w-5.5 pt-[3px] h-[26px]': true,
            flex: true,
            relative: true,
            'overflow-visible': true,
            'justify-end': true,
            'ltr:rounded-bl-lg rtl:rounded-br-lg': true,
            [bgColorClass]: true,
          }"
        >
          <!-- Static corner, hides when sliding corner is used -->
          <div
            v-if="xOverlay && !isWishContentScrollable"
            :class="[
              {
                absolute: true,
                'ltr:left-[-4px] rtl:right-[-4px] top-[7px]': !isStream,
                'ltr:left-[-4px] rtl:right-[-4px] top-[11px]': isStream,
                'rtl:-scale-x-100': true,
                [cornerColorClass]: true,
              },
              {
                'opacity-0': !xFillerElements || (xEditPost && isEditButtonFocused) || isMoreActionsButtonFocused,
                'transition opacity-100 group-hover:opacity-0':
                  xFillerElements && xEditPost && !isEditButtonFocused && !isMoreActionsButtonFocused,
              },
            ]"
          />
          <!-- 3 dots button -->
          <OzPlainButton
            v-emit-click-on-touch
            :color-scheme="OzPlainButtonColorScheme.SecondaryIcon"
            :dark-mode="colorScheme === 'dark'"
            :size-preset="null"
            :class="[
              'post-actions-button',
              'shrink-0',

              // Want: 10px between 3 dots at the edge.
              // Assume 3 dots width is 2px.
              // DistanceFromEdge - (IconWidth - 3DotsWidth) / 2 = 10px - (16px - 2px) / 2 = 10px - 7px = 3px

              !isTouchable && [
                // ContainerHeight - ContainerMargin = 29px - 3px = 26px
                'h-[26px]',
                'w-5.5',
                'py-0',
                'px-[3px]',
              ],
              isTouchable && [
                'w-[48px]',
                // 42px is the minimum height of the post, hence maximum height of the button.
                'h-[42px]',
                // Total = 48px - 16px = 32px
                'pl-[29px] pr-[3px] rtl:pr-[29px] rtl:pl-[3px]',
                // Total = 42px - 16px - 3px = 23px. We have to omit the top padding.
                'pt-[3px] pb-[20px]',
              ],
              // Focus
              colorScheme === 'light' && 'focus-visible:text-grape-500 focus-visible:border-grape',
              colorScheme === 'dark' && 'focus-visible:text-canary-500 focus-visible:border-canary',
              'border-2 focus-visible:border-solid border-transparent rounded-lg',
              'flex items-center justify-center',
              moreActionsButtonClasses,
            ]"
            :aria-label="__('More post actions')"
            :title="__('More post actions')"
            data-testid="surfacePostMoreActionsButton"
            @click="onMoreActionsButtonClick"
            @focus="isMoreActionsButtonFocused = true"
            @blur="isMoreActionsButtonFocused = false"
          >
            <OzIcon name="more_vertical" :size="16" />
          </OzPlainButton>
        </div>
      </div>
      <!-- Bottom corner -->
      <div
        v-if="xOverlay && !isWishContentScrollable"
        :class="[
          {
            absolute: true,
            'bottom-[-4px] ltr:right-[7px] rtl:left-[7px]': !isStream,
            'bottom-[-4px] ltr:right-[11px] rtl:left-[11px]': isStream,
            'rtl:-scale-x-100': true,
            [cornerColorClass]: true,
            'opacity-0': !xFillerElements || isMoreActionsButtonFocused,
          },
        ]"
      />
    </div>
  </div>
</template>

<style lang="scss" scoped>
@mixin corner($color) {
  width: 5px;
  height: 5px;
  background: radial-gradient(circle at bottom left, transparent 4px, $color 4.1px);
}
.corner-light-ui-100 {
  @include corner(theme('colors.light-ui.100'));
}
.corner-dark-ui-100 {
  @include corner(theme('colors.dark-ui.100'));
}
.corner-scarlet-100 {
  @include corner(theme('colors.scarlet.100'));
}
.corner-scarlet-900 {
  @include corner(theme('colors.scarlet.900'));
}
.corner-canary-100 {
  @include corner(theme('colors.canary.100'));
}
.corner-tangerine-900 {
  @include corner(theme('colors.tangerine.900'));
}
.corner-park-100 {
  @include corner(theme('colors.park.100'));
}
.corner-park-900 {
  @include corner(theme('colors.park.900'));
}
.corner-oceanic-100 {
  @include corner(theme('colors.oceanic.100'));
}
.corner-oceanic-900 {
  @include corner(theme('colors.oceanic.900'));
}
.corner-grape-100 {
  @include corner(theme('colors.grape.100'));
}
.corner-grape-900 {
  @include corner(theme('colors.grape.900'));
}
</style>
