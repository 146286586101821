// JQuery plugin to provide touch actions like pinch.
// https://github.com/visiongeist/toe.js
import { jQuery } from '@@/bits/jquery'
;(function (f, i, l) {
  function x() {
    var a = f(this),
      c = a.data('toe') || 0
    0 === c && (a.on('touchstart', r), a.on('touchmove', s), a.on('touchend touchcancel', t))
    a.data('toe', ++c)
  }
  function y() {
    var a = f(this),
      c = a.data('toe') || 0
    a.data('toe', --c)
    0 === c && (a.off('touchstart', r), a.off('touchmove', s), a.off('touchend touchcancel', t))
  }
  var m = {
      getTouches: function (a) {
        return 0 < a.originalEvent.touches.length
          ? f.extend(!0, {}, a.originalEvent.touches)
          : 0 < a.originalEvent.changedTouches.length
          ? f.extend(!0, {}, a.originalEvent.changedTouches)
          : []
      },
      hasEvent: function (a, c) {
        return a.data('events') ? a.data('events')[c] : 0
      },
    },
    g = {
      getDirection: function (a) {
        if (-45 > a && -135 < a) return 'top'
        if (-45 <= a && 45 >= a) return 'right'
        if (45 <= a && 135 > a) return 'down'
        if (135 <= a || -135 >= a) return 'left'
      },
      getAngle: function (a, c) {
        return (180 * Math.atan2(c.pageY - a.pageY, c.pageX - a.pageX)) / Math.PI
      },
      getScale: function (a, c) {
        return 2 === a.length && 2 === c.length
          ? Math.sqrt(Math.pow(c[0].pageX - c[1].pageX, 2) + Math.pow(c[0].pageY - c[1].pageY, 2)) /
              Math.sqrt(Math.pow(a[0].pageX - a[1].pageX, 2) + Math.pow(a[0].pageY - a[1].pageY, 2))
          : 0
      },
      getRotation: function (a, c) {
        return 2 === a.length && 2 === c.length
          ? (180 * Math.atan2(c[0].pageY - c[1].pageY, c[0].pageX - c[1].pageX)) / Math.PI -
              (180 * Math.atan2(a[0].pageY - a[1].pageY, a[0].pageX - a[1].pageX)) / Math.PI
          : 0
      },
      getDistance: function (a, c) {
        return Math.sqrt(Math.pow(c.pageX - a.pageX, 2) + Math.pow(c.pageY - a.pageY, 2))
      },
    },
    b = {
      touches: {},
      events: {},
      timestamp: l,
      prevGesture: null,
      offset: {},
      clearState: function () {
        b.touches = {}
        b.events = {}
        b.timestamp = l
      },
    },
    h = {
      start: [],
      move: [],
      end: [],
      none: [],
      add: function (a, c, v, d) {
        var b,
          e,
          g = !1
        if (d)
          for (b = 0; b < h[a].length; b++)
            (e = h[a][b]),
              (e.priority && e.priority < d) || (h[a].splice(b, 0, { gesture: c, func: v, priority: l }), (g = !0))
        ;(!d || !g) && h[a].push({ gesture: c, func: v, priority: l })
        z && (f.event.special[c] = { setup: x, teardown: y })
      },
      exec: function (a, c) {
        var b
        for (b = 0; b < h[a].length; b++) h[a][b].func(c)
      },
    },
    z = 'ontouchstart' in i,
    r,
    s,
    t
  r = f.proxy(function (a) {
    var c = f(a.target)
    b.clearState()
    b.touches.start = m.getTouches(a)
    b.events.start = a
    b.timestamp = new Date().getTime()
    b.events.start = a
    b.offset = c.offset()
    h.exec('start', a)
  }, this)
  s = f.proxy(function (a) {
    b.timestamp && ((b.touches.move = m.getTouches(a)), (b.events.move = a), h.exec('move', a))
  }, this)
  t = f.proxy(function (a) {
    b.timestamp &&
      ((b.touches.end = m.getTouches(a)),
      (b.events.end = a),
      h.exec('end', a),
      (b.prevGesture = b.gesture),
      b.clearState())
  }, this)
  var j = b
  h.add('end', 'swipe', function (a) {
    var c = new Date().getTime() - j.timestamp,
      b
    j.touches.move &&
      ((b = g.getDistance(j.touches.start[0], j.touches.move[0])),
      300 > c &&
        30 < b &&
        ((c = g.getAngle(j.touches.start[0], j.touches.move[0])),
        (c = g.getDirection(c)),
        (j.gesture = 'swipe'),
        f(a.target).trigger(
          f.Event('swipe', { originalEvent: a.originalEvent, direction: c, touches: f.extend(!0, {}, j.touches) }),
        )))
  })
  var i = h,
    d = b,
    n,
    k,
    p,
    w
  i.add('end', 'tap', function (a) {
    var c = new Date().getTime(),
      b = c - d.timestamp,
      e = f(a.target)
    500 < b ||
      ((a = d.events.start.originalEvent),
      (b = a),
      k &&
      'tap' === d.prevGesture &&
      300 > d.timestamp - p &&
      k &&
      d.touches.start &&
      20 > g.getDistance(k[0], d.touches.start[0])
        ? ((d.gesture = 'doubletap'),
          (p = null),
          f(b.target).trigger(f.Event('doubletap', { originalEvent: b })),
          (b = !0))
        : (b = !1),
      b ||
        ((n = d.touches.move ? g.getDistance(d.touches.start[0], d.touches.move[0]) : 0),
        10 > n &&
          ((d.gesture = 'tap'),
          (p = c),
          (k = d.touches.start),
          m.hasEvent(e, 'doubletap')
            ? setTimeout(function () {
                k &&
                  'doubletap' !== d.prevGesture &&
                  300 < new Date().getTime() - p &&
                  e.trigger(f.Event('tap', { originalEvent: a }))
              }, 300)
            : e.trigger(f.Event('tap', { originalEvent: a })))))
  })
  i.add('none', 'doubletap', f.noop)
  i.add('start', 'taphold', function (a) {
    d.gesture = 'taphold'
    clearTimeout(w)
    w = setTimeout(function () {
      d.touches.start &&
        !d.touches.end &&
        ((n = d.touches.move ? g.getDistance(d.touches.start[0], d.touches.move[0]) : 0),
        10 > n &&
          'taphold' === d.gesture &&
          f(a.target).trigger(
            f.Event('taphold', { originalEvent: d.events.start ? d.events.start.originalEvent : a.originalEvent }),
          ))
    }, 500)
  })
  var i = h,
    e = b,
    u = !1,
    q
  i.add('move', 'transform', function (a) {
    var c,
      b,
      d = f(a.target)
    if (
      2 === e.touches.move.length &&
      ((c = g.getRotation(e.touches.start, e.touches.move)),
      (b = g.getScale(e.touches.start, e.touches.move)),
      'transform' === e.gesture || 0.1 < Math.abs(1 - b) || 15 < Math.abs(c))
    )
      (e.gesture = 'transform'),
        (q = {
          pageX: (e.touches.move[0].pageX + e.touches.move[1].pageX) / 2 - e.offset.left,
          pageY: (e.touches.move[0].pageY + e.touches.move[1].pageY) / 2 - e.offset.top,
        }),
        u ||
          (d.trigger(f.Event('transformstart', { originalEvent: a.originalEvent, center: q, scale: b, rotation: c })),
          (u = !0)),
        d.trigger(f.Event('transform', { originalEvent: a.originalEvent, center: q, scale: b, rotation: c }))
  })
  i.add('end', 'transformend', function (a) {
    var c,
      b,
      d = f(a.target)
    'transform' === e.gesture &&
      ((c = g.getRotation(e.touches.start, e.touches.move)),
      (b = g.getScale(e.touches.start, e.touches.move)),
      d.trigger(f.Event('transformend', { originalEvent: a.originalEvent, center: q, scale: b, rotation: c })),
      (u = !1))
  })
})(jQuery, window)
